@use '../../../style/abstracts/style' as st;
.LisitingSection{
    flex-basis: 70%;
    .heading{
        width: 100%;
        justify-content: space-between;

        h1{
            font-size: st.$h2FontSize;
            color: st.$blaclColor;
            font-weight: 700;
        }
        .btn{
            border: 1px solid transparent;
        }
        .btn:hover{
            border: 1px solid st.$PrimaryColor;
        }
    }
    .secContainer{
        width: 100%;
        margin: 1rem 0;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: space-between;

        .singleItem{
            position: relative;
            width: 140px;
            height: 150px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            border-radius: 10px;
            background: st.$itemCardColor;
           
            &:hover{
                background: st.$itemCardHome;
                box-shadow: 0 2px 2px st.$inputColor ;
            }
            
            .icon{
                position: absolute;
                top: 10px;
                right: 10px;
                color: st.$PrimaryColor;
            }
            h3{
                font-size: st.$h3FontSize;
                color: st.$blaclColor;
                padding: 1rem 0;
            }
            img{
                width: 100%;
                height: 75px;
                object-fit: cover;
            }
        }
        
    }
    .chart{
        width: 90%;
    }
}
