@use '../../style/abstracts/style' as st;

.sideBar{
  width: 18%;
  height: 100%;
  overflow: hidden;

  display: grid;
  grid-template-areas: 
  "aa"
  "cc"
  "cc"
  "dd"
  ;




  .logoDiv {
    grid-area: aa;
    padding: 1.5rem 1.5rem 0;
    cursor: pointer;
    img {
      max-width: 20px;
      margin-right: 0.5rem;
      border-radius: 2px;
      padding: 0.2rem;
    }
  }
  .menuDiv{
    grid-area: cc;
    .divTitle {
      font-size: st.$h3FontSize;
      padding: 0 1.5rem 0 1.5rem;
    }
    .menuLists {
      .listItem {
        padding: 0.2rem 1.5rem;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          height:  0%;
          left: 0;
          width: 5px;
          bottom: 0;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .menuLink {
          font-weight: 500;
          .icon {
            margin-right: 0.5rem;
          }
        }

        &:hover .menuLink{
          transition: 0;
        }

        &:hover::before{
          height: 100%;
          transition: .3s  ease;
        }
      }
    }
  }
  .sideBarCard{
    grid-area:dd;
    margin: .4rem;
    text-align: center;
    position: relative;
 
    .CardContent{
      padding: 1rem;
      border-radius: 10px;
      overflow: auto;
    }
  }
  .darkmode{
    display: none;
  }
}
