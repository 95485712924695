
    $PrimaryColor: hsl(94, 59%, 35%);
    $HoverColor: #28361f;
    $paleGreen: hsl(0, 0%, 30%);
    $blaclColor: hsl(0, 0%, 51%);
    $whileColor: hsl(0, 1%, 29%);
    $textColor: hsl(240, 100%, 100%);
    $bgColor: hsl(240, 2%, 12%);
    $greyText: rgb(123, 122, 122);
    $inputColor: hsl(300, 1%, 36%);
    $itemCardColor:hsl(0, 1%, 23%);
    $itemCardHome:hsl(0, 0%, 100%);
