@use '../../../style/abstracts/style.scss' as st;
.topSection{
    .headerSection{
        justify-content: space-between;
        .title{
            h1{
                font-size: st.$h1FontSize;
                font-weight: 700;
            }
            p{
                font-size: st.$smallFontSize;
                font-weight: 500;
            }
        }

        .timeHome{
            text-align: center;
            h1{
                font-size: st.$h1FontSize;
                font-weight: 800;
            }
            p{
                font-size: st.$smallFontSize;
                font-weight: 500;
            }
        }

        .adminDiv{
            gap: 1rem;
            .icon{
                font-size: st.$biggestFontSize;
                border-radius: 5px;
                padding: 5px;
            }
            .adminImage{
                border-radius: 10px;
                width: 3.5rem;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

        }
    }

    .cardSection{
        margin-top: 3rem;
        gap: 1rem;
        .rightCard{
            position: relative;
            flex-basis: 70%;
            flex-direction: column;
            align-items: flex-start;
            justify-content:center ;
            margin: auto;
            padding: 2rem;
            border-radius: 1rem;
            overflow: hidden;

            .videoDiv{
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                .video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            h1,p,.buttons{
                z-index: 100;
            }
            h1{
                font-size: 800;
            }
            p{
                padding: 1rem;
                font-size: 500;
            }
            #namehome{
                margin-right: 1rem;
            }
            .buttons{
                gap: 1rem;
                .btn{
                    box-sizing: border-box;
                    padding: .8rem 1.5rem;
                    border: 2px solid transparent;
                }
                .transparent{
                    background: none;
                    border: 2px solid st.$paleGreen;
                    color: st.$paleGreen;

                    &:hover{
                        background: st.$paleGreen;
                        color: st.$PrimaryColor;
                    }
                }
            }
        }
        .leftCard{
            flex-basis: 50%;
            .main{
                position: relative;
                padding: 1rem;
                height: 200px;
                border-radius: 1rem;
                justify-content: space-between;

                &::before{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 75%;
                    background: st.$paleGreen;
                    left: 0;
                    bottom: 0;
                    border-radius: 1rem;
                }

                .textDiv{
                    z-index: 100;

                    .h1{
                        font-size: st.$h1FontSize;
                        padding-bottom: 1rem;
                        color: st.$blaclColor;
                    }

                    .flex{
                        gap:1.5rem;
                        font-weight: 500;
                        color: st.$blaclColor;

                        smail{
                            font-size: st.$normalFontSize;
                            display: block;
                            color: st.$PrimaryColor;
                            font-size: 500;
                            padding: .5rem 0;
                        }
                    }
                    .link{
                        margin-top: 2rem;
                        cursor: pointer;
                        &:hover{
                            color: st.$PrimaryColor;
                            .icon{
                                transform: translateX(10px);
                                transition: .3s ease;
                            }
                        }
                    }
                }
                .imgDiv{
                    transform: translateY(-20px);
                    .img{
                        width: 200px;
                    }
                }
            }
            
        }
       
    }

}



 






