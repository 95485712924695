/*  */
.topSection .headerSection {
  justify-content: space-between;
}
.topSection .headerSection .title h1 {
  font-size: 1.5rem;
  font-weight: 700;
}
.topSection .headerSection .title p {
  font-size: 0.813rem;
  font-weight: 500;
}
.topSection .headerSection .timeHome {
  text-align: center;
}
.topSection .headerSection .timeHome h1 {
  font-size: 1.5rem;
  font-weight: 800;
}
.topSection .headerSection .timeHome p {
  font-size: 0.813rem;
  font-weight: 500;
}
.topSection .headerSection .adminDiv {
  gap: 1rem;
}
.topSection .headerSection .adminDiv .icon {
  font-size: 2.5rem;
  border-radius: 5px;
  padding: 5px;
}
.topSection .headerSection .adminDiv .adminImage {
  border-radius: 10px;
  width: 3.5rem;
  overflow: hidden;
}
.topSection .headerSection .adminDiv .adminImage img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.topSection .cardSection {
  margin-top: 3rem;
  gap: 1rem;
}
.topSection .cardSection .rightCard {
  position: relative;
  flex-basis: 70%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  padding: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}
.topSection .cardSection .rightCard .videoDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.topSection .cardSection .rightCard .videoDiv .video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.topSection .cardSection .rightCard h1, .topSection .cardSection .rightCard p, .topSection .cardSection .rightCard .buttons {
  z-index: 100;
}
.topSection .cardSection .rightCard h1 {
  font-size: 800;
}
.topSection .cardSection .rightCard p {
  padding: 1rem;
  font-size: 500;
}
.topSection .cardSection .rightCard #namehome {
  margin-right: 1rem;
}
.topSection .cardSection .rightCard .buttons {
  gap: 1rem;
}
.topSection .cardSection .rightCard .buttons .btn {
  box-sizing: border-box;
  padding: 0.8rem 1.5rem;
  border: 2px solid transparent;
}
.topSection .cardSection .rightCard .buttons .transparent {
  background: none;
  border: 2px solid hsl(96, 75%, 100%);
  color: hsl(96, 75%, 100%);
}
.topSection .cardSection .rightCard .buttons .transparent:hover {
  background: hsl(96, 75%, 100%);
  color: hsl(94, 59%, 35%);
}
.topSection .cardSection .leftCard {
  flex-basis: 50%;
}
.topSection .cardSection .leftCard .main {
  position: relative;
  padding: 1rem;
  height: 200px;
  border-radius: 1rem;
  justify-content: space-between;
}
.topSection .cardSection .leftCard .main::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 75%;
  background: hsl(96, 75%, 100%);
  left: 0;
  bottom: 0;
  border-radius: 1rem;
}
.topSection .cardSection .leftCard .main .textDiv {
  z-index: 100;
}
.topSection .cardSection .leftCard .main .textDiv .h1 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: hsl(0, 0%, 18%);
}
.topSection .cardSection .leftCard .main .textDiv .flex {
  gap: 1.5rem;
  font-weight: 500;
  color: hsl(0, 0%, 18%);
}
.topSection .cardSection .leftCard .main .textDiv .flex smail {
  font-size: 0.938rem;
  display: block;
  color: hsl(94, 59%, 35%);
  font-size: 500;
  padding: 0.5rem 0;
}
.topSection .cardSection .leftCard .main .textDiv .link {
  margin-top: 2rem;
  cursor: pointer;
}
.topSection .cardSection .leftCard .main .textDiv .link:hover {
  color: hsl(94, 59%, 35%);
}
.topSection .cardSection .leftCard .main .textDiv .link:hover .icon {
  transform: translateX(10px);
  transition: 0.3s ease;
}
.topSection .cardSection .leftCard .main .imgDiv {
  transform: translateY(-20px);
}
.topSection .cardSection .leftCard .main .imgDiv .img {
  width: 200px;
}/*# sourceMappingURL=Top.css.map */