/*  */
.sideBar {
  width: 18%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas: "aa" "cc" "cc" "dd";
}
.sideBar .logoDiv {
  grid-area: aa;
  padding: 1.5rem 1.5rem 0;
  cursor: pointer;
}
.sideBar .logoDiv img {
  max-width: 20px;
  margin-right: 0.5rem;
  border-radius: 2px;
  padding: 0.2rem;
}
.sideBar .menuDiv {
  grid-area: cc;
}
.sideBar .menuDiv .divTitle {
  font-size: 1rem;
  padding: 0 1.5rem 0 1.5rem;
}
.sideBar .menuDiv .menuLists .listItem {
  padding: 0.2rem 1.5rem;
  position: relative;
}
.sideBar .menuDiv .menuLists .listItem::before {
  position: absolute;
  content: "";
  height: 0%;
  left: 0;
  width: 5px;
  bottom: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.sideBar .menuDiv .menuLists .listItem .menuLink {
  font-weight: 500;
}
.sideBar .menuDiv .menuLists .listItem .menuLink .icon {
  margin-right: 0.5rem;
}
.sideBar .menuDiv .menuLists .listItem:hover .menuLink {
  transition: 0;
}
.sideBar .menuDiv .menuLists .listItem:hover::before {
  height: 100%;
  transition: 0.3s ease;
}
.sideBar .sideBarCard {
  grid-area: dd;
  margin: 0.4rem;
  text-align: center;
  position: relative;
}
.sideBar .sideBarCard .CardContent {
  padding: 1rem;
  border-radius: 10px;
  overflow: auto;
}
.sideBar .darkmode {
  display: none;
}/*# sourceMappingURL=Sidebar.css.map */