
    $PrimaryColor: hsl(94, 59%, 35%);
    $HoverColor: #bdf094;
    $paleGreen: hsl(96, 75%, 100%);
    $blaclColor: hsl(0, 0%, 18%);
    $whileColor: hsl(0, 0%, 100%);
    $textColor: hsl(240, 1%, 48%);
    $bgColor: hsl(220, 10%, 94%);
    $greyText: rgb(190, 190, 190);
    $inputColor: hsl(330, 12%, 97%);
    $itemCardColor:hsl(120,5%,88%);
    $itemCardHome:hsl(120,27%,87%);
    /*  */
    $biggestFontSize: 2.5rem;
    $h1FontSize: 1.5rem;
    $h2FontSize: 1.25rem;
    $h3FontSize: 1rem;
    $normalFontSize: 0.938rem;
    $smallFontSize: 0.813rem;
    $smallestFontSize: 0.75rem;
