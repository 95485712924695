.containerRoom {
  gap: 1rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.containerRoom .singleItem {
  width: 95%;
  margin-top: 10px;
  border-radius: 1rem;
  transform: 0.3s;
  cursor: pointer;
}
.containerRoom .singleItem .content {
  position: relative;
  width: 100%;
}
.containerRoom .singleItem .content .imageRoom {
  height: 33rem;
}
.containerRoom .singleItem .content .imageRoom img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 1rem;
}
.containerRoom .singleItem .content .texth {
  position: absolute;
  z-index: 5;
  font-size: 9rem;
  padding-left: 1rem;
}
.containerRoom .singleItem .diverRoom {
  display: none;
  transform: scale(0.5);
}
.containerRoom .singleItem:hover {
  align-items: center;
  align-content: space-between;
  transition: 0.5s ease-in;
  width: 100%;
}
.containerRoom .Tabledevice {
  width: 100%;
  margin-bottom: 1rem;
}/*# sourceMappingURL=RoomPage.css.map */