.darkmode{
    label{
        position: relative;
        width: 7rem;
        height: 3.5rem;
        display: block;
        background: #d9d9d9;
        border-radius: 3rem;
        cursor: pointer;
        box-shadow: inset 0px 5px 15px rgba(0,0,0,0.3), inset 0px -5px 15px rgba(255,255,255,0.3);
      }
      label:after{
        content: '';
        position: absolute;
        height: 2.7rem;
        width: 2.7rem;
        background: #f2f2f2;
        border-radius: 3rem;
        top: 0.3rem;
        left: 0.5rem;
        transition: 0.5s;
        box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      }
      input:checked ~ label:after{
        left: 6.5rem;
        transform: translateX(-100%);
        background: linear-gradient(180deg,#777,#3a3a3a);
      }
      input:checked ~ label{
        background: #242424;
      }
      input:checked + label + .background{
        background: #242424;
      }
      input{
        display: none;
      }  
}